/* body {
  width: calc(100vw - 10px);
} */
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
::-webkit-scrollbar:vertical {
    width: 11px;
}

::-webkit-scrollbar:horizontal {
    height: 11px;
}
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aaa; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }