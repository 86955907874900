body {
  margin: 0;
  font-family:  'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.map {
  width: 100%;
}
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 150px;

}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}
.map:-webkit-full-screen {
  height: 100%;
  margin: 0;
}
.map:fullscreen {
  height: 100%;
}
.map .ol-rotate {
  top: 3em;
}
.map-container{
  width: 100%;
}
.ol-zoomx {
  /* Change the position of the zoom controls */
  top: 10px;
  right: 300px;
  left: auto;
}
.ol-fullscreenx {
  /* Change the position of the zoom controls */
  top: 70px;
  right: 300px;
  left: auto;
}
.ol-zoom2 {
  /* Change the position of the zoom controls */
  top: 10px;
  right: 10px;
  left: auto;
}
.ol-fullscreen2 {
  /* Change the position of the zoom controls */
  top: 70px;
  right: 10px;
  left: auto;
}