@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

h2 {
  text-align: center;
  font-family: "Playfair-Display";
  font-size: 1.8rem;
  margin-bottom: 5px;
}
p {
  font-size: 1rem;
}
.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.tab {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #4ba046;
  color: #fff;
  cursor: pointer;
}

.tab.active {
  background-color: #c4ecc2;
  color: #111;
}

.carousel-container {
  width: 90%;
  margin: auto;
}

.carousel .slide {
  display: flex;
  flex-direction: row;
  
}

.carousel-slide {
  display: flex;
  flex-direction: row;
  align-items:normal;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
}

button:hover {
  background-color: #ddd;
}

.text-area {
  flex: 1;
  padding-top: 0px;
  padding-left: 20px;
  padding-bottom: 0px;
  padding-right: 20px;
  text-align: left;
  background: #ddf0db;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  /* border: 1px solid #ccc; */
  border-radius: 10px; /* Add rounded edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.image-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  border-radius: 10px; /* Add rounded edges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-area img {
  width: 100%;
  height: 100%;
}

button {
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  color: #333;
  font-weight: bold;
  margin-top: 20px;
}

button:hover {
  background-color: #ddd;
}
.spaced-list li {
  margin-bottom: 15px; /* Adjust the value as needed for desired spacing */
  font-size: 1rem;
}
